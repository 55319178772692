import axios from 'axios';
import Cookies from 'js-cookie';

import { auth } from '../services/FirebaseClient';
import { showToast } from '../store/dashboard.reducer';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL + '/api/',
});

const getUserToken = async () => {
  return new Promise(async (resolve, reject) => {
    const unsub = auth.onAuthStateChanged(async (user) => {
      try {
        if (user) {
          const idToken = await user.getIdToken(true);
          resolve(idToken);
        } else {
          resolve(null);
        }
      } catch (error) {
        reject(error); // Reject with the error if something goes wrong
      } finally {
        unsub();
      }
    });
  });
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const userToken = await getUserToken();
    config.headers.Authorization = `Bearer ${userToken}`;

    return config;
  },
  (error) => Promise.reject(error),
);
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { status, data } = error?.response;
    const errorCode = status || 401;

    if (errorCode === 403) {
      window.location.href = '/error/403';
    } else {
      showToast({
        text: data?.data?.message || data?.data?.data?.message || 'Internal Server Error',
        type: 'error',
      });
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
