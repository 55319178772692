import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  submitSuccess: boolean;
}

const initialState: InitialState = {
  submitSuccess: false,
};

export const slice = createSlice({
  name: 'runningAdsSlice',
  initialState: {
    ...initialState,
  },
  reducers: {
    setSubmitSuccess(state, action: PayloadAction<InitialState>) {
      state.submitSuccess = action.payload.submitSuccess;
    },
  },
});

export const { setSubmitSuccess } = slice.actions;
export default slice.reducer;
