const QUERIES = {
  USERS_LIST: 'users-list',
  CUSTOMER_LIST: 'customer-list',
  CUSTOMER_ADDRESS_LIST: 'customer-address-list',
  PRODUCT_CATEGORY_LIST: 'product-category-list',
  PRODUCT_SUB_CATEGORY: 'product-sub-category-list',
  PRODUCT_LIST: 'product-list',
};

export { QUERIES };
