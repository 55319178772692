import { createSlice } from '@reduxjs/toolkit';
import { BlogScarlettResponse } from '../../types/blog';

interface BlogScarlettState {
  data: Partial<BlogScarlettResponse>;
  submitSuccess?: boolean;
}

const initialState: BlogScarlettState = {
  data: {},
  submitSuccess: false,
};

export const slice = createSlice({
  name: 'blogScarlettSlice',
  initialState,
  reducers: {
    setBlogScarlettList(state, action) {
      state.data = action.payload;
    },
    setSubmitSuccess(state, action) {
      state.submitSuccess = action.payload.submitSuccess;
    },
  },
});

export const { setBlogScarlettList, setSubmitSuccess } = slice.actions;
export default slice.reducer;
